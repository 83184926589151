import { isEmpty, some } from 'lodash';

const defaultNormalizeByIdHander = object => object;

export const getAllDescendantIds = ({ nodesByIds, nodeId }) =>
  !isEmpty(nodesByIds[nodeId]) && nodesByIds[nodeId].childrenIds
    ? nodesByIds[nodeId].childrenIds.reduce(
        (acc, childId) => [
          ...acc,
          childId,
          ...getAllDescendantIds({ nodesByIds, nodeId: childId })
        ],
        []
      )
    : [];

export const getAllAncestorsIds = ({ nodesByIds, nodeId }) =>
  !isEmpty(nodesByIds[nodeId]) && nodesByIds[nodeId].parentId
    ? [
        nodesByIds[nodeId].parentId,
        ...getAllAncestorsIds({ nodesByIds, nodeId: nodesByIds[nodeId].parentId })
      ]
    : [];

export const normalizeById = (entities = [], handler = defaultNormalizeByIdHander) => {
  return entities.reduce((res, object) => {
    res[object.id] = { id: object.id, type: object.type, ...handler(object) };
    return res;
  }, {});
};

export const assignChildrenToParents = ({ nodesByIds }) => {
  const newNodes = Object.values(nodesByIds).map(object => {
    return { ...object, childrenIds: [] };
  });
  const updatedNodesByIds = normalizeById(newNodes);
  newNodes.forEach(object => {
    const parent = updatedNodesByIds[object.parentId];
    if (parent) {
      parent.childrenIds = [...parent.childrenIds, object.id];
    }
  });
  return updatedNodesByIds;
};

export const rootIds = ({ nodesByIds }) => {
  return Object.values(nodesByIds)
    .filter(node => isEmpty(nodesByIds[node.parentId]))
    .map(node => node.id);
};

export const doesAnyParentHasProperty = ({ nodesByIds, nodeId, propertySelector }) => {
  const parentsIds = getAllAncestorsIds({ nodesByIds, nodeId });

  const filteredParents = parentsIds.filter(parentId =>
    propertySelector(nodesByIds[parentId] || {})
  );

  return some(filteredParents);
};
