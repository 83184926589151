import { createReduxResource } from 'core/redux/resources';

export const communicationReviewsResource = createReduxResource({
  name: 'communicationReviews',
  additionalReducer: {
    loadSucceed: (state, { payload }) => ({
      ...state,
      loading: false,
      byIds: { ...payload }
    })
  }
});
