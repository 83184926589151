import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { operations } from 'redux/lists/communicationReviewsList';
import { FileText, List, Mail, MessageCircle, Phone } from 'react-feather';
import CommunicationReviewsTable from './CommunicationReviewsTable';
import { CLIENT_INTERACTIONS_TYPES, COMMUNICATION_TYPES } from '../../../../core/utils/constants';

const ShowReviewsButton = ({ clientInteractionId }) => {
  const dispatch = useDispatch();
  const { page } = useSelector(state => state.communicationReviewsList);
  const [visible, setVisible] = useState(false);
  const clientInteractionById = useSelector(
    state => state.clientInteractionsResource.byIds[clientInteractionId]
  );
  const typeToIcon = {
    [COMMUNICATION_TYPES.TEXT]: FileText,
    [COMMUNICATION_TYPES.PHONE_CALL]: Phone,
    [COMMUNICATION_TYPES.CUSTOM]: List,
    [CLIENT_INTERACTIONS_TYPES.TICKET]: FileText,
    [CLIENT_INTERACTIONS_TYPES.EMAIL]: Mail,
    [CLIENT_INTERACTIONS_TYPES.CHAT]: MessageCircle,
    empty: null
  };
  const Icon = typeToIcon[clientInteractionById?.clientInteractionType];
  const showModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  useEffect(async () => {
    if (visible) {
      await dispatch(
        operations.load({
          client_interaction_id: clientInteractionId,
          page: page.number,
          count: page.size
        })
      );
    }
  }, [visible]);

  return (
    <>
      <Tooltip title="Проверки">
        {Icon && (
          <Icon onClick={showModal} style={{ marginTop: '6px', width: '24px', height: '16px' }} />
        )}
      </Tooltip>
      {visible && (
        <CommunicationReviewsTable
          visible={visible}
          closeModal={closeModal}
          clientInteractionId={clientInteractionId}
        />
      )}
    </>
  );
};

export default ShowReviewsButton;
