export default {
  organizationStructure: {
    moved: 'Переместить',
    moveToUnit: 'Переместить в отдел',
    employeesList: 'Список добавленных сотрудников',
    addEmployee: 'Добавить сотрудника',
    tabs: {
      units: {
        title: 'Структура компании',
        modal: {
          title: 'Добавить отдел',
          ok: 'Добавить',
          cancel: 'Отмена',
          form: {
            name: 'Название отдела',
            namePlaceholder: 'Введите название отдела',
            messages: {
              errors: {
                enterName: 'Пожалуйста, введите название отдела'
              }
            }
          }
        },
        confirmDelete: {
          title: 'Удалить отдел?',
          ok: 'Удалить',
          cancel: 'Отмена'
        }
      },

      employees: {
        title: 'Сотрудники',
        table: {
          columns: {
            name: 'Сотрудник',
            unit: 'Отдел',
            managedUnits: 'Дополнительные отделы',
            unitsTooltip:
              'Отдел, в котором находится пользователь и на который регистрируются его коммуникации. У пользователя есть доступ к данным указанного отдела, а также к данным отделов, находящимся ниже отдела пользователя по уровню иерархии (в той же ветке иерархии), в рамках установленных прав доступа для роли пользователя.',
            managedUnitsTooltip:
              'Доступ к просмотру и внесению изменений в данные о коммуникациях, проверках, пользователях, апелляциях, формах оценки в рамках настроек прав доступа в разделе “Права доступа и уровни сотрудников”',
            role: 'Роль',
            level: 'Уровень',
            access: 'Доступ',
            status: 'Статус'
          },
          filters: {
            totalCountEmployees: 'Всего пользователей:',
            employeesSearch: 'Поиск',
            unitsIdsPlaceholder: 'Выберите отдел',
            unitsIdsMaxPlaceholder: 'Отделы:',
            rolesIdsPlaceholder: 'Выберите роль',
            rolesIdsMaxPlaceholder: 'Роли:',
            statusPlaceholder: 'Выберите статус',
            invitationStatusPlaceholder: 'Выберите уровень доступа'
          },
          buttons: {
            addEmployee: 'Добавить пользователя'
          },
          menu: {
            items: {
              activate: 'Активировать',
              deactivate: 'Деактивировать',
              sendInvite: 'Отправить приглашение',
              editEmployee: 'Редактировать',
              employeeProfile: 'Перейти в профиль',
              deleteEmployee: 'Удалить',
              deleteEmployeeStatus: 'Удаляется'
            },
            messages: {
              invitationSended: 'Приглашение отправлено'
            }
          }
        },
        modal: {
          title: 'Настройки пользователя',
          buttons: {
            sendInvite: 'Отправить приглашение в систему',
            cancel: 'Отменить',
            save: 'Сохранить'
          },
          messages: {
            employee: 'Сотрудник',
            created: 'добавлен',
            updated: 'обновлен'
          },
          form: {
            fields: {
              lastName: 'Фамилия',
              lastNamePlaceholder: 'Введите фамилию',
              firstName: 'Имя',
              firstNamePlaceholder: 'Введите имя и отчество',
              email: 'E-mail',
              emailPlaceHolder: 'Введите e-mail',
              integrationId: 'Id в интегрируемой системе',
              integrationIdPlaceholder: 'Введите id в интегрируемой системе',
              role: 'Роль',
              rolePlaceholder: 'Выберите роль',
              unit: 'Отдел',
              unitPlaceHolder: 'Выберите отдел',
              managedUnits: 'Дополнительные отделы',
              managedUnitsPlaceholder: 'Выберите дополнительные отделы'
            },
            messages: {
              enterLastName: 'Пожалуйста, введите фамилию',
              enterFirstName: 'Пожалуйста, введите имя',
              enterEmail: 'Пожалуйста, введите e-mail',
              enterValidEmail: 'Вы ввели недопустимый e-mail',
              enterRole: 'Пожалуйста, выберите роль',
              enterUnit: 'Пожалуйста, выберите отдел',
              onlyRootUnitForAdmin: 'Админ может быть назначен только на головной отдел',
              allUnitsAreVisible: 'Пользователь уже имеет доступ ко всем отделам организации'
            }
          }
        },
        lastAuthorization: 'Последняя авторизация',
        accessLevels: {
          notInvited: 'Приглашение не отправлено',
          invited: 'Приглашение отправлено',
          loggedIn: 'Вход выполнен',
          noAccess: 'Нет доступа',
          buttons: {
            setLevel: 'Задать уровень',
            resetLevel: 'Сбросить уровень'
          }
        },
        limitCasesModal: {
          usersCountLimit: {
            text: 'Лимит по количеству пользователей превышен',
            description:
              'Для организации не может быть добавлено более 1500 пользователей. Пожалуйста, проверьте условие лицензии вашей организации или обратитесь в нашу поддержку.'
          },
          licenceCountLimit: {
            text: 'Лимит по количеству активных пользователей превышен',
            description_start:
              'Количество активных пользователей не может превышать количество приобретенных лицензий. Количество приобретенных лицензий для вашей организации – ',
            description_end:
              'Пожалуйста, проверьте условие вашей лицензии или обратитесь в нашу поддержку.'
          },
          closeButton: 'Обратиться в поддержку'
        },
        isLimitExpired: {
          usersCountLimit: {
            text: 'Превышен лимит активных пользователей',
            descriptionStart: 'Чтобы восстановить работу сервиса, деактивируйте',
            user: 'пользователя',
            users: 'пользователей',
            descriptionEnd: 'или свяжитесь с нами для оплаты дополнительных лицензий.'
          },
          isLicenseExpired: {
            text: 'Истек срок действия лицензии',
            description:
              'Уважаемый пользователь, срок действия вашей лицензии истек. Чтобы разблокировать доступ, свяжитесь с нами для продления лицензии.'
          }
        }
      },

      roles: {
        title: 'Права доступа и уровни сотрудников',
        item: {
          buttons: {
            setting: 'Настроить',
            delete: 'Удалить',
            newRole: 'Новая роль',
            addRole: 'Добавить роль'
          },
          confirmDelete: 'Удалить роль?',
          header: 'Название роли'
        },
        modal: {
          title: 'Настройка прав доступа',
          buttons: {
            update: 'Обновить',
            save: 'Сохранить',
            cancel: 'Отмена'
          },
          messages: {
            roleSuccessfullyUpdated: 'Роль успешно обновлена',
            updateRoleFailed: 'Не удалось обновить роль',
            saveRoleFailed: 'Не удалось сохранить роль',
            roleSuccessfullySaved: 'Роль создана'
          },
          form: {
            nameCategory: 'Название роли',
            generalCategory: 'Общие',
            profileCategory: 'Личный кабинет',
            analyticsCategory: 'Аналитика, оценка и обратная связь',
            tasksCategory: 'Задачи',
            libraryCategory: 'Библиотека звонков',
            administrateCategory: 'Администрирование'
          }
        }
      },

      levels: {
        title: 'Уровни сотрудников',
        item: {
          buttons: {
            setting: 'Настроить уровни'
          },
          header: 'Название роли и уровни'
        },
        modal: {
          title: 'Настройка уровней роли',
          form: {
            namePlaceholder: 'Введите название уровня'
          },
          buttons: {
            save: 'Сохранить',
            cancel: 'Отмена',
            addLevel: 'Добавить уровень'
          },
          messages: {
            hasEmptyNames: 'Названия уровней не могут быть пустыми',
            hasSameNames: 'Нельзя сохранить уровни с одинаковыми названиями'
          }
        }
      },

      statuses: {
        title: 'Статусы контакта',
        form: {
          namePlaceholder: 'Введите название статуса',
          empty: 'Не создано статусов',
          addStatus: 'Добавить статус',
          save: 'Сохранить'
        }
      },
      notifications: {
        title: 'Уведомления и статусы',
        table: {
          title: 'Настройка уведомлений для роли:',
          rolePlaceholder: 'Выберите роль',
          columns: {
            eventType: 'Тема уведомления',
            emailNotification: 'Уведомление по e-mail'
          },
          buttons: {
            setting: 'Настроить'
          }
        },
        modal: {
          title: 'Настройка уведомлений:',
          isSendEmail: {
            title: 'Отправлять уведомление на e-mail?',
            true: 'Да',
            false: 'Нет'
          }
        }
      },
      connections: {
        title: 'Подключения',
        table: {
          userHasNoIntegrations: 'Пользователь не добавлен к интеграциям',
          deleteFrom: 'Удалить из',
          users: 'Пользователей'
        },
        userAccounts: {
          userConnectedToIntegration: 'Пользователь подключен к интеграции',
          selectUserAccount: 'Выбрать учётную запись',
          downloadingAccounts: 'Загружаем аккаунты',
          integrationHasNoAccounts: 'У данной интеграции нет аккаунтов для подключения'
        }
      }
    },
    menu: {
      add: 'Добавить отдел',
      rename: 'Переименовать',
      delete: 'Удалить'
    }
  }
};
