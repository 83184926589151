import React, { useEffect, useState } from 'react';
import { get, isEmpty, isEqual } from 'lodash';
import { Button, Col, Collapse } from 'antd';
import { ChevronsDown, ChevronsUp } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getCustomFieldsByKeys } from 'redux/selectors/customFields';
import { customFieldsResource } from 'redux/resources/customFields';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';
import AiIcon from 'assets/images/ai-icon.svg';
import SCard from './Standard/SCard';
import SRow from './Standard/SRow';
import Icon from './Icon';
import { SButton, SText } from './Standard';
import CustomFieldsModal from './CommunicationInfoModal/CustomFieldsModal';
import CustomFieldValueView from './CustomFieldValueView';
import { Info } from './Info';
import { isCustomFieldVisible } from '../core/utils/isCustomFieldVisible';
import CustomFieldsView from './CommunicationInfoModal/CustomFieldsView';

const { Panel } = Collapse;

const CollapseButton = styled(Button)`
  &.ant-btn {
    text-transform: capitalize;
    bottom: -20px;
    z-index: 2;
    position: absolute;
    min-width: max-content;
    height: 20px;
    color: #82858c;
    display: flex;
    font-weight: bold;
    font-size: 12px;
    padding: 0 5px 0 4px;
    flex-direction: row-reverse;
    right: 50%;
    transform: translate(50%, -50%);
    & > span {
      margin-top: auto;
      padding: 0px 3px 0;
    }
    &.ant-btn:hover,
    :active {
      color: #839df2 !important;
    }
    &.ant-btn:focus {
      color: rgb(130, 133, 140);
      border-color: rgb(232, 232, 232);
    }

    & > svg {
      margin: auto;
    }
  }
`;

const StyledCollapse = styled(Collapse)`
  && {
    background-color: inherit;
    & > .ant-collapse-item {
      border: 0;
      & > .ant-collapse-header {
        display: none !important;
      }
      &.ant-collapse-item-active {
        padding: 0px;
      }
      & > .ant-collapse-content {
        & > .ant-collapse-content-box {
          padding: 24px;
        }
      }
    }
  }
`;

const CustomFields = ({ clientInteraction }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState([]);

  useEffect(() => {
    dispatch(customFieldsResource.operations.load({ pagination: false }));
  }, []);

  const customFieldsByKeys = useSelector(state => getCustomFieldsByKeys(state), isEqual);
  const { loading } = useSelector(state => state.customFieldsResource);

  const communicationChainCustomFields = clientInteraction?.customFields
    ? clientInteraction?.customFields
    : null;
  const [isEditMode, setIsEditMode] = useState(false);
  const [isActiveModeForSave, setIsActiveModeForSave] = useState(false);
  const [fieldValues, setFieldValues] = useState(communicationChainCustomFields);
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const handleCollapse = e => {
    e.preventDefault();
    if (open.length === 0) setOpen(['1']);
    else setOpen([]);
  };
  const handleSave = () => {
    dispatch(
      clientInteractionsResource.operations.updateClientInteractionCustomFields({
        client_interaction_id: clientInteraction?.id,
        organization_id: organizationId,
        fieldValues: JSON.stringify(fieldValues)
      })
    );
    setIsActiveModeForSave(false);
    setIsEditMode(false);
  };
  return (
    <>
      {!isEmpty(communicationChainCustomFields) && (
        <Col span={24} style={{ padding: '0' }}>
          <SCard
            bordered="1px solid #e4e6ed"
            borderTop="none"
            headPadding="8px 24px"
            bodyPadding="0px"
            loading={loading}
            marginBottom="15px"
            rounded="8px"
            position="relative"
            zIndex="1"
          >
            <StyledCollapse bordered={false} activeKey={open} accordion={false}>
              <Panel key="1" showArrow={false} collapsible="disabled" header="customFields2">
                {isEditMode ? (
                  <CustomFieldsModal
                    customFields={communicationChainCustomFields}
                    customFieldsByKeys={customFieldsByKeys}
                    isEditMode={isActiveModeForSave}
                    setIsEditMode={setIsActiveModeForSave}
                    fieldValues={fieldValues}
                    setFieldValues={setFieldValues}
                  />
                ) : (
                  <CustomFieldsView
                    customFieldsByKeys={customFieldsByKeys}
                    communicationChainCustomFields={communicationChainCustomFields}
                    clientInteraction={clientInteraction}
                  />
                )}
                <SRow justifyContent="flex-end">
                  {!isEditMode ? (
                    <SButton onClick={() => setIsEditMode(true)} showLoad type="primary">
                      {t('general.edit')}
                    </SButton>
                  ) : isActiveModeForSave ? (
                    <SButton onClick={handleSave} showLoad type="primary">
                      {t('general.save')}
                    </SButton>
                  ) : (
                    <SButton onClick={() => setIsEditMode(false)} showLoad type="primary">
                      {t('general.cancel')}
                    </SButton>
                  )}
                </SRow>
              </Panel>
            </StyledCollapse>
            <CollapseButton
              shape="round"
              icon={
                open.length === 0 ? (
                  <Icon icon={ChevronsDown} size={14} style={{ strokeWidth: '3' }} />
                ) : (
                  <Icon icon={ChevronsUp} size={14} style={{ strokeWidth: '3' }} />
                )
              }
              onClick={handleCollapse}
            >
              Кастомные поля
            </CollapseButton>
          </SCard>
        </Col>
      )}
    </>
  );
};

export default CustomFields;
