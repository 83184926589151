import React, { useEffect } from 'react';
import { Modal, Skeleton } from 'antd';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import Text from 'antd/lib/typography/Text';
import { connect } from 'react-redux';
import { actions, operations } from 'redux/lists/communicationReviewsList';
import { withRouter } from 'react-router-dom';
import { beatifyFloat } from 'core/utils/convertFloat';
import styled from 'styled-components';
import { STable } from '../../../Standard';
import ClientInteractionLink from '../../../ClientInteractionLink';
import UserPreview from '../../../UserPreview';

const CenteredData = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CommunicationReviewsTable = ({
  visible,
  closeModal,
  clientInteractionId,
  page,
  totalCount,
  loading,
  communicationReviews,
  checklistDefinitions,
  checklists,
  checklistScores,
  updatePage,
  loadCommunicationReviews
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    loadCommunicationReviews({
      client_interaction_id: clientInteractionId,
      page: page.number,
      count: page.size
    });
  }, [page.number, page.size]);

  const handleTableChange = pagination => {
    const { pageSize, current } = pagination;
    updatePage({
      size: pageSize.toString(),
      number: current.toString()
    });
  };

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      width: 40,
      title: 'Тип',
      align: 'center',
      render: (clientInteractionId, record) => {
        return (
          <ClientInteractionLink
            id={clientInteractionId}
            communication={record}
            useDrawer={false}
            isNewPage
          />
        );
      }
    },
    {
      key: 'reviewerId',
      dataIndex: 'reviewerId',
      title: 'ФИО проверяющего',
      align: 'center',
      render: reviewerId => {
        return (
          <CenteredData>
            <UserPreview userId={reviewerId} disabled showAvatar />
          </CenteredData>
        );
      }
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      width: 140,
      title: 'Дата проверки',
      align: 'center',
      render: createdAt => {
        return <Text>{moment(createdAt).format('DD/MM/YYYY')}</Text>;
      }
    },
    {
      key: 'checklistId',
      dataIndex: 'checklistId',
      title: 'Название формы оценки',
      align: 'center',
      render: checklistId => {
        const checklistDefinitionId = checklists.find(checklist => checklist?.id === checklistId)
          ?.checklistDefinitionId;
        const checklistDefinitionName = checklistDefinitions.find(
          checklistDefinition => checklistDefinition?.id === checklistDefinitionId
        )?.name;
        return <Text>{checklistDefinitionName}</Text>;
      }
    },
    {
      key: 'checklistScoreId',
      dataIndex: 'checklistScoreId',
      width: 70,
      title: 'Итог',
      align: 'center',
      render: checklistScoreId => {
        const checklistScore = checklistScores.find(score => score.id === checklistScoreId)?.score;
        return <Text>{beatifyFloat(checklistScore ?? 'N/A')}</Text>;
      }
    }
  ];

  return (
    <Modal
      visible={visible}
      maskClosable
      destroyOnClose
      width={980}
      title="Проверки по коммуникации"
      onCancel={closeModal}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      {loading && <Skeleton active />}
      {!isEmpty(communicationReviews) && !loading && (
        <STable
          onChange={handleTableChange}
          dataSource={communicationReviews}
          columns={columns}
          size="small"
          bordered
          border
          rowkey="id"
          pagination={{
            pageSize: parseInt(page.size),
            current: parseInt(page.number),
            total: totalCount
          }}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = state => {
  const { page, totalCount, loading } = state.communicationReviewsList;

  const communicationReviewsResource = state.communicationReviewsResource.byIds;
  const communicationReviews = Object.values(communicationReviewsResource);

  const checklistDefinitionsResource = state.checklistDefinitionsResource.byIds;
  const checklistDefinitions = Object.values(checklistDefinitionsResource);

  const checklistsResource = state.checklistsResource.byIds;
  const checklists = Object.values(checklistsResource);

  const checklistScoresResource = state.checklistScoresResource.byIds;
  const checklistScores = Object.values(checklistScoresResource);

  return {
    page,
    totalCount,
    loading,
    communicationReviews,
    checklistDefinitions,
    checklists,
    checklistScores
  };
};
const mapDispatchToProps = {
  updatePage: actions.updatePage,
  loadCommunicationReviews: operations.load
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommunicationReviewsTable));
