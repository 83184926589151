import { INTEGRATIONS } from 'core/utils/constants';
import { integrationsResource } from 'redux/resources/integrations';
import api, { s2SynchronizationEndpoint } from 'core/api';
import decamelizeKeys from 'decamelize-keys';
import moment from 'moment';
import { Col, Modal, Row, Spin, Typography } from 'antd';
import React from 'react';
import parseOptionsForApi from '../../../core/utils/parseOptionsForApi';
import { processJsonApiCollection } from '../../../core/jsonapi';
import { saveBackgroundJob } from '../backgroundJob/operations';

let integration = null;
let errorInstall = true;
const modal = Modal;
export const createPyrusIntegration = ({
  name,
  clientSecret,
  organization_id,
  username
}) => async dispatch => {
  try {
    await (async function() {
      integration = await dispatch(
        integrationsResource.operations.create({
          name,
          username,
          clientSecret,
          organizationId: organization_id,
          integrationType: INTEGRATIONS.pyrus.type
        })
      );
      modal.confirm({
        title: 'Внимание',
        content: (
          <Row>
            <Col>
              <Typography.Text>Подождите, идет установка интеграции</Typography.Text>
            </Col>
            <Col style={{ width: '100%', textAlign: 'center' }}>
              <Spin />
            </Col>
          </Row>
        ),
        cancelButtonProps: { style: { display: 'none' } },
        okButtonProps: { style: { display: 'none' } },
        okText: 'Ok',
        closable: false,
        maskClosable: false
      });
      await api
        .createPyrusIntegration(
          parseOptionsForApi({
            name,
            username,
            clientSecret,
            organizationId: organization_id,
            integrationType: INTEGRATIONS.pyrus.type
          })
        )
        .then(() => (errorInstall = true))
        .catch(() => (errorInstall = false));
    })();
  } catch (error) {
    console.log(error);
  } finally {
    modal.destroyAll();
    console.log(errorInstall);
    if (integration && errorInstall) {
      dispatch(saveBackgroundJob(integration.currentBackgroundJob));
      dispatch(
        integrationsResource.actions.createSucceed({ ...integration, integrationType: 'pyrus' })
      );
      return integration.id;
    }
    dispatch(integrationsResource.operations.deleteById({ id: integration.id, type: integration.integrationType }));
  }
};

export const syncDataWithPyrus = ({ id, fetchFrom }) => async () => {
  try {
    await fetch(
      `${s2SynchronizationEndpoint}${id}/sync?from=${moment(fetchFrom).format(
        'YYYY-MM-DD HH:mm:ss.SSS'
      )}&to=${moment(Date.now()).format('YYYY-MM-DD HH:mm:ss.SSS')}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  } catch (error) {
    console.error('Error during synchronization:', error);
  }
};

export const setPyrusIntegrationData = async (id, otherFields, name, organizationId) => {
  try {
    const response = await api.updateS2Integration(
      { id },
      decamelizeKeys({
        name,
        username: otherFields.username,
        client_secret: otherFields.clientSecret,
        organization_id: organizationId
      })
    );

    return processJsonApiCollection(response.body.data);
  } catch (error) {
    console.log(error);
  }
};
export const getEntityProjectPyrus = async integrationId => {
  try {
    const response = await api.getEntityProjectPyrus({ id: integrationId });

    return response?.body?.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const connectPyrusProjectById = (id, integrationId) => async () => {
  try {
    const response = await api.connectEntityProjectPyrus(
      { id: integrationId },
      { subIntegrationId: id }
    );
    return response?.body;
  } catch (error) {
    console.log(error);
  }
};
export const disconnectPyrusProjectById = (id, integrationId) => async () => {
  try {
    const response = await api.disconnectEntityProjectPyrus(
      { id: integrationId },
      { subIntegrationId: id }
    );
    return response?.body;
  } catch (error) {
    console.log(error);
  }
};
export const connectStatusById = (id, integrationId) => async () => {
  try {
    const response = await api.connectEntityStatusPyrus({ id: integrationId }, { contextId: id });
    return response?.body;
  } catch (error) {
    console.log(error);
  }
};
export const disconnectStatusById = (id, integrationId) => async () => {
  try {
    const response = await api.discconnectEntityStatusPyrus(
      { id: integrationId },
      { contextId: id }
    );
    return response?.body;
  } catch (error) {
    console.log(error);
  }
};
export const connectAllStatusPyrus = (id, all) => async () => {
  try {
    await api.connectAllStatusPyrus({ id }, parseOptionsForApi({ connect: all }));
    return true;
  } catch (error) {
    console.log(error);
  }
};
export const getEntityCustomFieldsPyrus = ({ id, location, currentUserId }) => async () => {
  try {
    if (
      location &&
      currentUserId &&
      !location.pathname.includes(`/user/${currentUserId}/integrations-settings/pyrus/`)
    ) {
      await api.refreshEntityCustomFieldsPyrus({ id }, {});
    }
    const response = await api.getEntityCustomFieldsPyrus({ id });
    return processJsonApiCollection(response.body.data);
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const connectCustomFieldPyrus = ({ id, fieldId }) => async () => {
  try {
    await api.connectEntityCustomFieldsPyrus({ id }, { customFieldId: fieldId });
    return true;
  } catch (error) {
    console.log(error);
  }
};

export const disconnectCustomFieldPyrus = ({ id, fieldId }) => async () => {
  try {
    await api.disconnectEntityCustomFieldsPyrus({ id }, { customFieldId: fieldId });
    return true;
  } catch (error) {
    console.log(error);
  }
};

export const getEntityStatusPyrus = async integrationId => {
  try {
    const response = await api.getEntityStatusPyrus({ id: integrationId });
    return response?.body?.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const connectAllCustomFieldsPyrus = ({ id, all }) => async () => {
  try {
    await api.connectAllEntityCustomFieldsPyrus({ id }, parseOptionsForApi({ connect: all }));
    return true;
  } catch (error) {
    console.log(error);
  }
};
export const connectAllProjectsPyrus = (id, all) => async () => {
  try {
    await api.connectAllProjectsPyrus({ id }, { connect: all });
    return true;
  } catch (error) {
    console.log(error);
  }
};
